import Base = require("Everlaw/Base");
import BaseSelect = require("Everlaw/UI/BaseSelect");

/**
 * We cannot update the abstract BaseSelect class directly, so we just provide an update
 * function on the BaseSelect parameters that Context[? extends BaseSelect] should use in
 * addition to any specific param updating.
 */
export function updateBaseSelectParams<S, T extends Base.Object>(
    baseSelectParams: BaseSelect.Params<S, T>,
    contextBaseSelectParams: Params<S, T>,
) {
    if (contextBaseSelectParams.nameMap) {
        baseSelectParams.nameMap = contextBaseSelectParams.nameMap;
        baseSelectParams.headers = true;
    }
    if (contextBaseSelectParams.newClassesInline) {
        baseSelectParams.newClassesInline = contextBaseSelectParams.newClassesInline;
    }
    if (contextBaseSelectParams.newElements) {
        baseSelectParams.elements = contextBaseSelectParams.newElements(baseSelectParams.elements);
    }
    if (contextBaseSelectParams.getHeader) {
        baseSelectParams.getHeader = contextBaseSelectParams.getHeader;
        baseSelectParams.headers = true;
    }
    if (contextBaseSelectParams.classOrder) {
        baseSelectParams.classOrder = contextBaseSelectParams.classOrder;
    }
    return baseSelectParams;
}

export interface Params<S, T extends Base.Object> {
    /** Overrides the BaseSelect#Params#nameMap argument. */
    nameMap?: { [className: string]: string };
    /** Overrides the BaseSelect#Params#newClassesInline argument. */
    newClassesInline?: BaseSelect.NewClassesInline<S, T>;
    /** Transform BaseSelect input elements. */
    newElements?: (elements: T[] | T[][]) => T[] | T[][];
    /** Overrides the BaseSelect#Params#getHeader argument. */
    getHeader?: (elem: T) => string;
    /** Overrides the BaseSelect#Params#classOrder argument. */
    classOrder?: ({ prototype: { className: string } } | string)[];
}
