import Context = require("Everlaw/Context/Context");

/**
 * A ContextElement should be used to mixin standard UI elements. They take additional parameters
 * (of type P) to determine how to modify the underlying UI element. New ContextElements should be
 * defined in the Context/UI/ directory and an entry should be added to the abstract Context class
 * so all extending contexts inherit its use. See Context/UI/SideBar.ts for an example.
 */
abstract class Element<T extends Context.Constructor, P extends {}> {
    abstract getBase(): T;
    abstract getContextParams(params: P): Context.Params<T>;
}

export = Element;
