import Arr = require("Everlaw/Core/Arr");
import ContextElement = require("Everlaw/Context/UI/Element");
import PopoverMenu = require("Everlaw/UI/PopoverMenu");

/**
 * A PopoverMenu for when in a non Everlaw context.
 */
const ContextPopoverMenu = new (class extends ContextElement<
    typeof PopoverMenu,
    ContextPopoverMenu.Params
> {
    getBase() {
        return PopoverMenu;
    }
    getContextParams(params: ContextPopoverMenu.Params) {
        return {
            updateArgs: (args: [PopoverMenu.Params, HTMLElement]) => {
                const menuItemOmissions = Arr.toSet(params.menuItemOmissions || []);
                args[0].menuItems = args[0].menuItems.filter(
                    (menuItem) => !menuItemOmissions.has(menuItem.id),
                );
                return args;
            },
        };
    }
})();

module ContextPopoverMenu {
    export interface Params {
        /** Menu items that should be omitted entirely from the tooltip menu. */
        menuItemOmissions?: string[];
    }
}

export = ContextPopoverMenu;
