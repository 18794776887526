import Base = require("Everlaw/Base");
import ContextElement = require("Everlaw/Context/UI/Element");
import ContextTable = require("Everlaw/Context/UI/Table");
import ContextTableBase = require("Everlaw/Context/UI/TableBase");
import Table = require("Everlaw/Table");

/**
 * A TableWidget for when in a non Everlaw context.
 */
const ContextTableWidget = new (class<
    OBJ extends Base.Object,
    DATA extends Table.RowData,
> extends ContextElement<typeof Table.Widget, ContextTable.Params<OBJ, DATA>> {
    getBase() {
        return Table.Widget;
    }
    getContextParams(params: ContextTable.Params<OBJ, DATA>) {
        return {
            updateArgs: (args) => [updateTableWidgetParams(args[0], params)],
        };
    }
})();

function updateTableWidgetParams<OBJ extends Base.Object, DATA extends Table.RowData>(
    tableWidgetParams: Table.WidgetParams<OBJ, DATA>,
    contextTableWidgetParams: ContextTableWidget.Params<OBJ, DATA>,
) {
    tableWidgetParams.createNew = contextTableWidgetParams.createNew || tableWidgetParams.createNew;
    tableWidgetParams.createNew =
        !contextTableWidgetParams.omitCreateNew && tableWidgetParams.createNew;
    return ContextTableBase.updateTableParams(tableWidgetParams, contextTableWidgetParams);
}

module ContextTableWidget {
    export interface Params<OBJ extends Base.Object, DATA extends Table.RowData>
        extends ContextTable.Params<OBJ, DATA> {
        /** Overwrites the widget's createNew parameter. */
        createNew?: Table.CreateNew;
        omitCreateNew?: boolean;
    }
}

export = ContextTableWidget;
