/**
 * Utility for manipulating the hash of the URL, i.e. the portion after the "#".
 * Setting the hash adds an entry to frontend navigation history
 * to allow moving forward/back between page states or to link to a particular page state.
 */

import Arr = require("Everlaw/Core/Arr");
import Obj = require("Everlaw/Core/Obj");
import Util = require("Everlaw/Util");

import { objectToQuery, ObjectToQueryMap, queryToObject } from "Everlaw/Core/Obj";
import dojo_on = require("dojo/on");
import { useEffect, useState } from "react";

interface UpdateParams<T> {
    /** Key-value pairs to add to the hash. */
    add?: Partial<T>;
    /** Key-value pairs to remove from the hash */
    remove?: keyof T | (keyof T)[];
    /**
     * Whether removals go before additions. Defaults to false, i.e. by default a key will be
     * removed if it appears in both `add` and remove`.
     */
    removeThenAdd?: boolean;
}

/**
 * Create a typed manager to manipulate the hash in a type safe fashion.
 * If you don't want type safety see the exported, "static", untyped functions lower in this file.
 * The exported functions are documented in lieu of these.
 */
export class Manager<T extends ObjectToQueryMap> {
    subscribe(onHashChange: (hash: T) => void) {
        return dojo_on(window, "hashchange", () => {
            onHashChange(this.get());
        });
    }
    get(): T {
        return queryToObject(getRaw()) as T;
    }
    getQueryParams(): T {
        return queryToObject(location.search.slice(1).replace(/\+/g, "%20")) as T;
    }
    set(hash: T, replaceCurrentHistory = false) {
        location[replaceCurrentHistory ? "replace" : "assign"]("#" + objectToQuery(hash));
    }
    /**
     * Returns true iff `hash` is the same as the current hash.
     * Assumes the current hash and given `hash` consist only of numbers, strings and booleans.
     * Doesn't handle arrays, or other non-primitive objects like Dates.
     * If you JSONify an object and put it in the hash the behavior is undefined.
     */
    isSame(other: T) {
        const hash = this.get();
        let count = 0;
        return (
            Object.entries(other || {}).every(([key, otherVal]) => {
                count++;
                return hash[key] === String(otherVal);
            }) && count === Obj.size(hash)
        );
    }

    /**
     * Returns the hash resulting from applying the updates to the current hash in the URL.
     */
    mix(params: UpdateParams<T>): T {
        let hash = this.get();
        const add = () => {
            if (!params.add) {
                return;
            }
            hash = Object.assign(hash, params.add);
        };
        const remove = () => {
            if (!params.remove) {
                return;
            }
            for (const key of Arr.wrap(params.remove)) {
                delete hash[key];
            }
        };
        if (params.removeThenAdd) {
            remove();
            add();
        } else {
            add();
            remove();
        }
        return hash;
    }

    update(params: UpdateParams<T>, replaceCurrentHistory = false) {
        const hash = this.mix(params);
        this.set(hash, replaceCurrentHistory);
        return hash;
    }
    add(toAdd: Partial<T>, replaceCurrentHistory = false) {
        return this.update({ add: toAdd }, replaceCurrentHistory);
    }
    remove(...keys: (keyof T)[]) {
        return this.update({ remove: keys });
    }
}

/// Static, untyped methods for manipulating the hash.

export interface Hash {
    [key: string]: any;
}

const STATIC = new Manager<Hash>();

/**
 * Subscribe a function to be called whenever the hash changes. The `hash` argument is a rich
 * object, not a hash string. Returns an object with a `remove()` method that unsubscribes.
 */
export function subscribe(onHashChange: (hash: Hash) => void) {
    return STATIC.subscribe(onHashChange);
}

/**
 * Set the hash to `hash`.
 *
 * @param replaceCurrentHistory true if you want to replace the current history entry rather than
 *        create a new entry
 */
export function set(hash: Hash, replaceCurrentHistory = false) {
    STATIC.set(hash, replaceCurrentHistory);
}

/**
 * Get the current hash parameters as an object.
 */
export function get() {
    return STATIC.get();
}

/**
 * Get the current query parameters as an object.
 */
export function getQueryParams(): Hash {
    return STATIC.getQueryParams();
}

/** Get the raw hash string. */
export function getRaw() {
    return location.hash.slice(1);
}

/**
 * Returns true iff `hash` is exactly the same as the current hash parameters.
 */
export function isSame(hash: Hash) {
    return STATIC.isSame(hash);
}

/**
 * Update the hash according to `params` but don't set the new hash, just return it.
 */
export function mix(params: UpdateParams<any>) {
    return STATIC.mix(params);
}

/**
 * Update and set the hash according to `params`, returning the new hash.
 */
export function update(params: UpdateParams<Hash>, replaceCurrentHistory = false) {
    return STATIC.update(params, replaceCurrentHistory);
}

/**
 * Convenience for `update({ add: toAdd })`.
 */
export function add(toAdd: Hash, replaceCurrentHistory = false) {
    return STATIC.add(toAdd, replaceCurrentHistory);
}

/**
 * Convenience for `update({ remove: keys })`.
 *
 * NB: can't add the `replaceCurrentHistory` param and keep this variadic.
 * Use `update()` directly if you need to pass `replaceCurrentHistory = true`.
 */
export function remove(...keys: string[]) {
    return STATIC.remove(...keys);
}

/**
 * React hook for subscribing to URL hash updates. Re-renders whenever the hash changes.
 *
 * @param manager optional manager. Defaults to the STATIC, untyped manager (which is perfectly fine
 *                as you can type the return value however you want).
 * @returns the current URL hash as an object
 */
export function useUrlHash<H extends ObjectToQueryMap>(manager = <Manager<H>>STATIC): H {
    const [urlHash, setUrlHash] = useState(manager.get());
    useEffect(() => {
        const handle = manager.subscribe((hash) => setUrlHash(hash));
        return () => Util.destroy(handle);
    }, []);
    return urlHash;
}
